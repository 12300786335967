// src/blogData.js
export const blogs = [
    {
      id: 1,
      link: "https://gleaming-plough-001.notion.site/Transcription-2684b4a362bf488fa2d3f457f5f9bbef?pvs=4",
      title: 'Understanding React Hooks',
      date: 'October 1, 2023',
      excerpt: 'An in-depth look at React Hooks and how to use them in your applications...',
      imageUrl: 'blog2.jpeg',
      content: 'Full content of the blog post goes here...',
    },
    {
      id: 2,
      link: "https://gleaming-plough-001.notion.site/Transcription-2684b4a362bf488fa2d3f457f5f9bbef?pvs=4",
      title: 'Mastering JavaScript ES6',
      date: 'September 15, 2023',
      excerpt: 'Learn about the new features introduced in ES6 and how they can improve your code...',
      imageUrl: 'blog3.jpeg',
      content: 'Full content of the blog post goes here...',
    },
    {
        id: 3,
        link: "https://gleaming-plough-001.notion.site/Transcription-2684b4a362bf488fa2d3f457f5f9bbef?pvs=4",
        title: 'Mastering JavaScript ES6',
        date: 'September 15, 2023',
        excerpt: 'Learn about the new features introduced in ES6 and how they can improve your code...',
        imageUrl: 'blog5.jpeg',
        content: 'Full content of the blog post goes here...',
      },
      {
        id: 4,
        link: "https://gleaming-plough-001.notion.site/Transcription-2684b4a362bf488fa2d3f457f5f9bbef?pvs=4",
        title: 'Mastering JavaScript ES6',
        date: 'September 15, 2023',
        excerpt: 'Learn about the new features introduced in ES6 and how they can improve your code...',
        imageUrl: 'blog3.jpeg',
        content: 'Full content of the blog post goes here...',
      },
      {
        id: 5,
        link: "https://gleaming-plough-001.notion.site/Transcription-2684b4a362bf488fa2d3f457f5f9bbef?pvs=4",
        title: 'Mastering JavaScript ES6',
        date: 'September 15, 2023',
        excerpt: 'Learn about the new features introduced in ES6 and how they can improve your code...',
        imageUrl: 'blog2.jpeg',
        content: 'Full content of the blog post goes here...',
      },
      {
        id: 6,
        link: "https://gleaming-plough-001.notion.site/Transcription-2684b4a362bf488fa2d3f457f5f9bbef?pvs=4",
        title: 'Mastering JavaScript ES6',
        date: 'September 15, 2023',
        excerpt: 'Learn about the new features introduced in ES6 and how they can improve your code...',
        imageUrl: 'blog3.jpeg',
        content: 'Full content of the blog post goes here...',
      },
      {
        id: 7,
        link: "https://gleaming-plough-001.notion.site/Transcription-2684b4a362bf488fa2d3f457f5f9bbef?pvs=4",
        title: 'Mastering JavaScript ES6',
        date: 'September 15, 2023',
        excerpt: 'Learn about the new features introduced in ES6 and how they can improve your code...',
        imageUrl: 'blog5.jpeg',
        content: 'Full content of the blog post goes here...',
      },
      {
        id: 8,
        link: "https://gleaming-plough-001.notion.site/Transcription-2684b4a362bf488fa2d3f457f5f9bbef?pvs=4",
        title: 'Mastering JavaScript ES6',
        date: 'September 15, 2023',
        excerpt: 'Learn about the new features introduced in ES6 and how they can improve your code...',
        imageUrl: 'blog2.jpeg',
        content: 'Full content of the blog post goes here...',
      },
      {
        id: 9,
        link: "https://gleaming-plough-001.notion.site/Transcription-2684b4a362bf488fa2d3f457f5f9bbef?pvs=4",
        title: 'Mastering JavaScript ES6',
        date: 'September 15, 2023',
        excerpt: 'Learn about the new features introduced in ES6 and how they can improve your code...',
        imageUrl: 'blog3.jpeg',
        content: 'Full content of the blog post goes here...',
      },
      {
        id: 10,
        link: "https://gleaming-plough-001.notion.site/Transcription-2684b4a362bf488fa2d3f457f5f9bbef?pvs=4",
        title: 'Mastering JavaScript ES6',
        date: 'September 15, 2023',
        excerpt: 'Learn about the new features introduced in ES6 and how they can improve your code...',
        imageUrl: 'blog5.jpeg',
        content: 'Full content of the blog post goes here...',
      },
      
      
    // Add more blog posts as needed
  ];